import React from 'react';
import styled from 'styled-components';
import BodyCopyBlock from '@hoffman/shared/src/components/TextFeatures/bodyCopyBlock';
import {VerticalText} from '@hoffman/shared/src/components/VerticalText/verticalText';
import ScrollAnimation from 'react-animate-on-scroll';
import 'animate.css/animate.compat.css';
interface ContentPageProps {
  headlineSize: string;
  headlineText: string;
  copySize: string;
  copyText: string;
  blockWidth: string;
  rightPosition: boolean;
  topPosition: boolean;
  cta: boolean;
  ctaColor: string;
  ctaLink: string;
  ctaHover: string;
  ctaText: string;
  verticalText: string;
  verticalColor: string;
}
const ContentPageSection = ({
  headlineSize,
  headlineText,
  copySize,
  copyText,
  blockWidth,
  rightPosition,
  topPosition,
  cta,
  ctaColor,
  ctaLink,
  ctaHover,
  ctaText,
  verticalText,
  verticalColor,
}: ContentPageProps) => {
  return (
    <ResponsiveContainer>
      <Container>
        <BodyCopyContainer
          rightPosition={rightPosition}
          topPosition={topPosition}
        >
          <div>
            <BodyCopyBlock
              blockWidth={blockWidth}
              headlineSize={headlineSize}
              headlineText={headlineText}
              copySize={copySize}
              copyText={copyText}
              cta={cta}
              color={ctaColor}
              link={ctaLink}
              hover={ctaHover}
              text={ctaText}
            />
          </div>
        </BodyCopyContainer>
        <VerticalTextContainer>
          <ScrollAnimation
            animateIn="fadeInDown"
            animateOnce={true}
            duration={2}
            initiallyVisible={false}
            offset={10}
          >
            <VerticalText text={verticalText} color={verticalColor} />
          </ScrollAnimation>
        </VerticalTextContainer>
      </Container>
    </ResponsiveContainer>
  );
};

ContentPageSection.defaultProps = {
  headlineSize: '50px',
  headlineText: 'Headline',
  copySize: '20px',
  copyText: 'Add Copy Text Here',
  blockWidth: '500px',
  rightPosition: false,
  cta: false,
  color: 'black',
  link: 'https://google.com',
  hover: 'blue',
  text: 'Contact Us',
  verticalText: 'Lifestyle',
  verticalColor: 'black',
};
export default ContentPageSection;

const ResponsiveContainer = styled.div`
  @media (max-width: ${({theme}) => theme.breakpoints[1]}) {
    h1 {
      font-size: ${({theme, headlineSize}) =>
        headlineSize ? headlineSize : theme.fontSizes[5]}px;
      max-width: 245px;
    }
    p {
      font-size: ${({theme}) => theme.fontSizes[1]}px;
      line-height: 25px;
    }
  }
`;

//   margin: 100px 230px 100px 120px;

const Container = styled.div`
  display: grid;
  grid-template-columns: 80% 20%;
  ${({rightPosition, theme}) =>
    !rightPosition && `padding-left: ${theme.spaces[9]}px;`}

  @media (max-width: ${({theme}) => theme.breakpoints[0]}) {
    grid-template-columns: 100%;
    padding-left: 0;
  }
`;

const VerticalTextContainer = styled.div`
  margin-left: auto;
  margin-right: 0;

  @media (max-width: ${({theme}) => theme.breakpoints[0]}) {
    display: none;
  }
`;

const BodyCopyContainer = styled.div`
  display: flex;
  align-items: ${(props: ContentPageProps) =>
    props.topPosition ? 'flex-start' : 'flex-end'};
  justify-content: ${(props: ContentPageProps) =>
    props.rightPosition ? 'flex-end' : 'flex-start'};

  @media (max-width: ${({theme}) => theme.breakpoints[0]}) {
    justify-content: inherit;
  }
`;
