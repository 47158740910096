import React from 'react';
import styled from 'styled-components';
import {themes} from '../../theme';

interface CopyProps {
  text: string;
  size?: string;
  blockWidth?: string;
  subHeader?: boolean;
}

export const Copy = ({blockWidth, text, size, subHeader}: CopyProps) => {
  return text.split('\n\n').map((txt, i) => (
    <CopyContainer
      key={i}
      subHeader={subHeader}
      blockWidth={blockWidth}
      size={size}
    >
      {txt}
    </CopyContainer>
  ));
};

Copy.defaultProps = {
  text: 'Add body copy here...',
};

export default Copy;

const CopyContainer = styled.p`
  margin: 0;
  margin-top: 1em;
  font-family: ${({subHeader, theme}) =>
    subHeader ? theme.fontFaces.heading : theme.fontFaces.default};
  font-size: ${({size, theme}) => (size ? size : `${theme.fontSizes[2]}px`)};
  font-weight: ${({theme}) => theme.fontWeights.light};
  max-width: ${(props: CopyProps) => props.blockWidth};
  padding: 0;
  color: ${({theme}) => theme.colors.text};
  text-decoration: none;
  line-height: ${({theme}) => theme.lineHeights.body}em;

  &:first-child {
    margin-top: 0;
  }

  @media only screen and (max-width: ${({theme}) => theme.breakpoints[0]}) {
    font-size: ${({theme}) => theme.fontSizes[1]}px;
    font-family: ${({theme}) => theme.fontFaces.default};
  }
`;
