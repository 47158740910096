import React, {useContext, useEffect, useRef, useState} from 'react';
import {GatsbyImageFluidProps, FluidObject} from 'gatsby-image';
import BackgroundImage from 'gatsby-background-image';
import styled, {ThemeContext} from 'styled-components';
import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import {AmarisTheme} from '../theme';
import {useWindowSize} from '@hzdg/windowsize-monitor';

gsap.registerPlugin(ScrollTrigger);

const ExpandImgWrapper = styled.div`
  position: relative;
  height: 90vh;
  width: 100vw;
  box-sizing: border-box;
  overflow: hidden;
  max-width: 100%;
  @media screen and (max-width: ${({theme}) => theme.breakpoints[0]}) {
    width: 100%;
    height: min(90vh, 100vw * 2);
  }
`;

const positionMixin = (props: {objectPosition?: string}) => {
  if (props.objectPosition) {
    return `background-position: ${props.objectPosition} !important;`;
  }
  return null;
};

const BgImg = styled(BackgroundImage)`
  width: 100%;
  height: 100%;
  --reveal-padding: 0;
  &,
  &::before,
  &::after {
    position: relative;
    z-index: 1;
    background-origin: border-box;
    background-clip: content-box;
    box-sizing: border-box;
    padding: 0 var(--reveal-padding);
    ${positionMixin}
  }
`;

interface propsExpandImg extends GatsbyImageFluidProps {
  mobile?: FluidObject;
  objectPosition?: string;
}

// put this (or similar) into below function for higer-res mobile assets
// let mobileObj = {...props.mobile} as FluidObject; // typed, shallow clone
// // remove the lowest res sourceset item
// mobileObj.srcSet = mobileObj.srcSet.split(',').slice(1).join(',');

// future: we can extract this as a custom hook that grabs theme from context
const packMobileImg = (props: propsExpandImg, theme: AmarisTheme) => {
  let fluid = props.fluid;
  if (props.mobile) {
    fluid = [
      props.mobile,
      {
        ...props.fluid,
        media: `(min-width: ${theme.breakpoints[0]})`,
      } as FluidObject,
    ];
  }
  return {...props, fluid};
};

const ExpandImg = (props: propsExpandImg) => {
  const theme = useContext(ThemeContext) as AmarisTheme;
  const breakpx = theme.breakpx[0];

  // window size tracking allows us to unmount/remount the img whem switching
  // between normal and mobile sizes, for art direction
  const [sizeKey, setSizeKey] = useState('');
  useWindowSize(({width}) => {
    setSizeKey(width > breakpx ? 'normal' : 'mobile');
  });

  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const wrapperEl = wrapperRef.current;
    const imgEl = wrapperRef.current?.querySelector('div');

    if (wrapperEl && imgEl) {
      gsap.fromTo(
        imgEl,
        {
          // var is used in the styles to affect multiple/pseudo elements
          '--reveal-padding': '20vw',
          transform: 'scale(1)',
          opacity: 0,
        },
        {
          // var is used in the styles to affect multiple/pseudo elements
          '--reveal-padding': '0vw',
          opacity: 1,
          transform: 'scale(1.12)',
          scrollTrigger: {
            trigger: wrapperEl,
            start: 'top bottom',
            end: 'center center',
            scrub: 1,
            once: true,
          },
        },
      );
    }
  }, [sizeKey]);

  const imgProps = packMobileImg(props, theme);

  return (
    <ExpandImgWrapper ref={wrapperRef}>
      <BgImg {...imgProps} critical loading="eager" key={sizeKey} />
    </ExpandImgWrapper>
  );
};

export default ExpandImg;
