import React from 'react';
import styled from 'styled-components';

interface VerticalTextProps {
  text: string;
}
export const VerticalText = ({text, color}: VerticalTextProps) => {
  return (
    <Container>
      <Container color={color}>
        <VerticalLine />
        <VerticalWord>{text}</VerticalWord>
      </Container>
    </Container>
  );
};

VerticalText.defaultProps = {
  text: 'text',
};
export default VerticalText;

const Container = styled.div`
  position: relative;
  color: ${({theme}) => theme.colors.text};
`;

const VerticalLine = styled.div`
  width: 7px;
  height: 80px;
  border-right: 1px solid currentColor;
  position: relative;
  top: 0px;
  left: 0px;
`;

const VerticalWord = styled.div`
  top: 15px;
  position: relative;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  font-size: ${({theme}) => theme.fontSizes[1]}px;
  font-family: ${({theme}) => theme.fontFaces.default};
  font-weight: ${({theme}) => theme.fontWeights.light};
  text-transform: uppercase;
  letter-spacing: 3px;
  color: currentColor;
`;
